import { useEffect } from "react";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";
import "styles/modal.css";

const Tokenomics = () => {
  // some 1
  const { isConnected } = useAccount();

  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );
  const [initialStarsTop, setInitialStarsTop] = useAtom(
    Top.initialStarsTopAtom
  );
  const [animateStarsTop, setAnimateStarsTop] = useAtom(
    Top.animateStarsTopAtom
  );
  const [initialStarsLeft, setInitialStarsLeft] = useAtom(
    Top.initialStarsLeftAtom
  );
  const [animateStarsLeft, setAnimateStarsLeft] = useAtom(
    Top.animateStarsLeftAtom
  );
  const [initialStarsOpacity, setInitialStarsOpacity] = useAtom(
    Top.initialStarsOpacityAtom
  );
  const [animateStarsOpacity, setAnimateStarsOpacity] = useAtom(
    Top.animateStarsOpacityAtom
  );
  const [initialSunTop, setInitialSunTop] = useAtom(Top.initialSunTopAtom);
  const [animateSunTop, setAnimateSunTop] = useAtom(Top.animateSunTopAtom);
  const [initialSunLeft, setInitialSunLeft] = useAtom(Top.initialSunLeftAtom);
  const [animateSunLeft, setAnimateSunLeft] = useAtom(Top.animateSunLeftAtom);

  const [initialMainBottom, setInitialMainBottom] = useAtom(
    Bottom.initialMainBottomAtom
  );
  const [animateMainBottom, setAnimateMainBottom] = useAtom(
    Bottom.animateMainBottomAtom
  );
  const [initialMainLeft, setInitialMainLeft] = useAtom(
    Bottom.initialMainLeftAtom
  );
  const [animateMainLeft, setAnimateMainLeft] = useAtom(
    Bottom.animateMainLeftAtom
  );
  const [initialMainOpacity, setInitialMainOpacity] = useAtom(
    Bottom.initialMainOpacityAtom
  );
  const [animateMainOpacity, setAnimateMainOpacity] = useAtom(
    Bottom.animateMainOpacityAtom
  );
  const [initialMainScale, setInitialMainScale] = useAtom(
    Bottom.initialMainScaleAtom
  );
  const [animateMainScale, setAnimateMainScale] = useAtom(
    Bottom.animateMainScaleAtom
  );
  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  useEffect(() => {
    setSkyColor("#288fe3");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(0);
  });

  useEffect(() => {
    setInitialStarsTop(animateStarsTop);
    setInitialStarsLeft(animateStarsLeft);
    setInitialStarsOpacity(animateStarsOpacity);
    setAnimateStarsTop("0px");
    setAnimateStarsLeft("0px");
    setAnimateStarsOpacity(0);
    setInitialSunTop(animateSunTop);
    setInitialSunLeft(animateSunLeft);
    setAnimateSunTop("15rem");
    setAnimateSunLeft("16rem");
  });

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMainBottom(animateMainBottom);
    setInitialMainLeft(animateMainLeft);
    setInitialMainOpacity(animateMainOpacity);
    setInitialMainScale(animateMainScale);
    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("0vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(1)");

    setAnimateMainBottom("-20vh");
    setAnimateMainLeft("-20vw");
    setAnimateMainOpacity(0);
    setAnimateMainScale("scale(1.5)");
    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(0);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-0vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(0);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(1);
    setAnimateTokenomicsScale("scale(1.2)");
  });

  return (
    <div className="RouteWrapper TokenomicsWrapper">
      {/* ### SKY BACKGROUND ### */}

      <Top.TopBackground
        skyColorAtom={skyColor}
        initialBGOpacityAtom={initialBGOpacity}
        animateBGOpacityAtom={animateBGOpacity}
        initialStarsTopAtom={initialStarsTop}
        animateStarsTopAtom={animateStarsTop}
        initialStarsLeftAtom={initialStarsLeft}
        animateStarsLeftAtom={animateStarsLeft}
        initialStarsOpacityAtom={initialStarsOpacity}
        animateStarsOpacityAtom={animateStarsOpacity}
        initialSunTopAtom={initialSunTop}
        animateSunTopAtom={animateSunTop}
        initialSunLeftAtom={initialSunLeft}
        animateSunLeftAtom={animateSunLeft}
      />

      <div className="Base Tokenomics">
        {/* ##### VIEWPORT */}

        <div className="viewport">
          <div className="siteMap flx">
            <div className="breadcrumbs">
              <Link to="/">MCAA</Link> &nbsp; &#62; &nbsp;{" "}
              <Link className="" to="/tokenomics">
                tokenomics
              </Link>
            </div>
            <div className="next-bread">
              <Link to="/mint">LAST: MINT</Link>
            </div>
          </div>

          {/* ### CARD ### */}

          <motion.div
            style={{ overflow: "scroll" }}
            className="container"
            initial={{ opacity: 0, top: "150%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ type: "tween", duration: 0.4 }}
          >
            <div style={{ overflow: "auto" }}>
              <h3>Tokenomics</h3>
              <p>
                <a href="/operating-agreement.pdf" target="_blank">
                  <u>Operating agreement</u>
                </a>
              </p>
              <p>
                <b>Buddha Boy PMF DAO LLC NFT Ownership and Utility Contract</b>
              </p>
              <p>
                <b>Article 1: Introduction</b>
              </p>
              <ul>
                <li>
                  This contract governs the issuance and rights associated with
                  Non-Fungible Tokens (NFTs) in Buddha Boy PMF DAO LLC ("the
                  DAO"). These NFTs provide ownership, voting rights, and access
                  to a range of services and events, as detailed below.
                </li>
              </ul>

              <p>
                <b>
                  Article 2: Token Categories, Percentage of DAO Distribution,
                  Art Access, and Benefits
                </b>
              </p>
              <ul>
                <li>Section 2.1: Token Types and Benefits</li>

                <li>
                  <ul>
                    <li>1. Owners PMF Tokens (2 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 61.30% total, 30.65%
                        per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 72.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> Full.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Full access to all DAO
                        utilities, events, exclusive Buddha Boy artwork,
                        influencer access, PMF access, merchant access,
                        exclusive merchandise, ADGB conferences, discounts, and
                        more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>2. Custom Tokens (8 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 6.00% total, 0.75%
                        per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 6.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 50% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Similar to Owners PMF
                        tokens with some exclusions.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>3. Partners PMF Tokens (180 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 12.00% total,
                        0.066667% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 8.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 50% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Access to DAO
                        utilities, Buddha Boy artwork, influencer access, and
                        more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>4. Founders PMF Tokens (360 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 8.00% total,
                        0.022222% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 6.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 30% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Access to basic DAO
                        utilities, Buddha Boy artwork, and more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>5. Funder Associate Tokens (101 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 7.00% total,
                        0.069307% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 5.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 25% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> NBA Fan Boy NFT art,
                        selected PMF access, and more.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>6. Funder Community Tokens (520 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 3.00% total,
                        0.005769% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 1.30%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 20% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> NBA Fan Boy NFT art,
                        selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>7. Sponsor Tokens (52,000 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 2.00% total,
                        0.000038% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 1.00%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 10% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Local workshops,
                        selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>

                <br />

                <li>
                  <ul>
                    <li>8. Participant Tokens (101,000 Tokens)</li>
                    <ul>
                      <li>
                        <strong>Percentage of DAO:</strong> 0.70% total,
                        0.000007% per token.
                      </li>
                      <li>
                        <strong>Free airdrop percentage:</strong> 20%; Free
                        airdrop percentage Revenue Share to DAO: 0.70%.
                      </li>
                      <li>
                        <strong>Voting Rights:</strong> 10% of current
                        Percentage of DAO and voting rights.
                      </li>
                      <li>
                        <strong>Access/Utility:</strong> Special offers for
                        early buyers, selected PMF access.
                      </li>
                    </ul>
                  </ul>
                </li>
              </ul>

              <ul>
                <li>Section 2.2: Artwork Allocation</li>
                <li>
                  <ul>
                    <li>
                      - Tokens are non-refundable and represent a stake in the
                      DAO.
                    </li>
                    <li>
                      - Token holders must comply with DAO governance and
                      operational rules, as well as applicable laws and
                      regulations.
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                <b>Article 3: Terms of Sale, Transfer, and Compliance</b>
              </p>
              <ul>
                <li>
                  - Tokens are non-refundable and represent a stake in the DAO.
                </li>
                <li>
                  - Token holders must comply with DAO governance and
                  operational rules, as well as applicable laws and regulations.
                </li>
              </ul>

              <p>
                <b>Article 4: Legal and Regulatory Compliance</b>
              </p>
              <ul>
                <li>
                  - The DAO and token holders are subject to all relevant legal
                  and regulatory obligations.
                </li>
              </ul>

              <p>
                <b>Appendices</b>
              </p>
              <ul>
                <li>
                  - Detailed Percentage of DAO distribution, utility rights,
                  Free airdrop percentage structures, and specific art
                  allocations.
                </li>
                <li>
                  - DAO governance rules and token holder responsibilities.
                </li>
              </ul>
              <p className="border-b pb-4">
                <a href="https://metamask.io/buy-crypto/" target="_blank">
                  How to buy crypto currency in metamask using cards, banks of
                  digital wallets tutorial
                </a>
              </p>
              <br />
            </div>
          </motion.div>

          {/* ### BOTTOM BACKGROUND ### */}

          <motion.div
            className="BG transition"
            initial={{
              zIndex: 4,
              left: "-40vw",
              opacity: 1,
              transform: "scale(0.15) translate(-50%, -50%) rotate(-40deg)",
            }}
            animate={{
              zIndex: 3,
              left: "180vw",
              opacity: 1,
              transform: "scale(2.65) translate(-50%, -50%) rotate(0deg)",
            }}
            transition={{ delay: 1, type: "tween", duration: 3 }}
          >
            <img
              src="/train-alt.png"
              alt="Train"
              className="Train"
              priority="true"
            />
          </motion.div>

          <Bottom.BottomBackground
            initialMainBottomAtom={initialMainBottom}
            animateMainBottomAtom={animateMainBottom}
            initialMainLeftAtom={initialMainLeft}
            animateMainLeftAtom={animateMainLeft}
            initialMainOpacityAtom={initialMainOpacity}
            animateMainOpacityAtom={animateMainOpacity}
            initialMainScaleAtom={initialMainScale}
            animateMainScaleAtom={animateMainScale}
            initialMintBottomAtom={initialMintBottom}
            animateMintBottomAtom={animateMintBottom}
            initialMintLeftAtom={initialMintLeft}
            animateMintLeftAtom={animateMintLeft}
            initialMintOpacityAtom={initialMintOpacity}
            animateMintOpacityAtom={animateMintOpacity}
            initialMintScaleAtom={initialMintScale}
            animateMintScaleAtom={animateMintScale}
            initialTimelineBottomAtom={initialTimelineBottom}
            animateTimelineBottomAtom={animateTimelineBottom}
            initialTimelineLeftAtom={initialTimelineLeft}
            animateTimelineLeftAtom={animateTimelineLeft}
            initialTimelineOpacityAtom={initialTimelineOpacity}
            animateTimelineOpacityAtom={animateTimelineOpacity}
            initialTimelineScaleAtom={initialTimelineScale}
            animateTimelineScaleAtom={animateTimelineScale}
            initialAboutBottomAtom={initialAboutBottom}
            animateAboutBottomAtom={animateAboutBottom}
            initialAboutLeftAtom={initialAboutLeft}
            animateAboutLeftAtom={animateAboutLeft}
            initialAboutOpacityAtom={initialAboutOpacity}
            animateAboutOpacityAtom={animateAboutOpacity}
            initialAboutScaleAtom={initialAboutScale}
            animateAboutScaleAtom={animateAboutScale}
            initialFoundersBottomAtom={initialFoundersBottom}
            animateFoundersBottomAtom={animateFoundersBottom}
            initialFoundersLeftAtom={initialFoundersLeft}
            animateFoundersLeftAtom={animateFoundersLeft}
            initialFoundersOpacityAtom={initialFoundersOpacity}
            animateFoundersOpacityAtom={animateFoundersOpacity}
            initialFoundersScaleAtom={initialFoundersScale}
            animateFoundersScaleAtom={animateFoundersScale}
            initialVisionBottomAtom={initialVisionBottom}
            animateVisionBottomAtom={animateVisionBottom}
            initialVisionLeftAtom={initialVisionLeft}
            animateVisionLeftAtom={animateVisionLeft}
            initialVisionOpacityAtom={initialVisionOpacity}
            animateVisionOpacityAtom={animateVisionOpacity}
            initialVisionScaleAtom={initialVisionScale}
            animateVisionScaleAtom={animateVisionScale}
            initialTokenomicsBottomAtom={initialTokenomicsBottom}
            animateTokenomicsBottomAtom={animateTokenomicsBottom}
            initialTokenomicsLeftAtom={initialTokenomicsLeft}
            animateTokenomicsLeftAtom={animateTokenomicsLeft}
            initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
            animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
            initialTokenomicsScaleAtom={initialTokenomicsScale}
            animateTokenomicsScaleAtom={animateTokenomicsScale}
            initialTitleTopAtom={initialTitleTop}
            animateTitleTopAtom={animateTitleTop}
            initialTitleLeftAtom={initialTitleLeft}
            animateTitleLeftAtom={animateTitleLeft}
            initialTitleOpacityAtom={initialTitleOpacity}
            animateTitleOpacityAtom={animateTitleOpacity}
            initialTitleScaleAtom={initialTitleScale}
            animateTitleScaleAtom={animateTitleScale}
          />
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
